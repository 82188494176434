<template>
	<div class="layout_root">
		<el-scrollbar style="height: 100%;width: 100%;">
			<el-container>
				<el-header>
					<Header />
				</el-header>
				<el-main>
					<router-view />
				</el-main>
			</el-container>
		</el-scrollbar>
	</div>
</template>

<script>
import Header from "@/layout/components/Header";
export default {
	name: "index",
	components: {
		Header
	}
}
</script>

<style scoped>
.layout_root{
	height: 100vh;
	width: 100%;
	background-color: rgba(247,248,252);
	/*border: 1px solid #423e3e;*/
}

.el-header{
	height: 50px;
	width: 100%;
	position: fixed;
	padding: 0 !important;
	background-color: rgba(31,34,51);
}

.el-main{
	margin: 70px;
	height: 100%;
	/*border: 1px solid #c52828;*/
}

/deep/ .el-scrollbar__wrap {
	overflow-x: hidden;
}
</style>
