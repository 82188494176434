import request from "@/utils/request";

let user = {};
let token = ''

if(localStorage.getItem("token")){
    token = JSON.parse(localStorage.getItem("token"));
    user = getUser();
}

function getUser(){
    request({
        url:'/dormitory/selectUserByToken',
        method:'get',
        params:{
            token:token
        }
    }).then(res => {
        if(res.code === '200'){
            user = res.data;
            // console.log('user:'+JSON.stringify(user))
        }
    })
}


export default user
