import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from '@/layout'
import {Message} from 'element-ui';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: layout,
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('../views/HomeView.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/activity',
    component: layout,
    children: [
      {
        path: '/activity',
        name: 'activity',
        component: () => import('../views/ActivityView.vue')
      }
    ]
  },
  {
    path: '/money',
    component: layout,
    children: [
      {
        path: '/money',
        name: 'money',
        component: () => import('../views/MoneyView.vue')
      }
    ]
  },
  {
    path: '/attendance',
    component: layout,
    children: [
      {
        path: '/attendance',
        name: 'attendance',
        component: () => import('../views/AttendanceView.vue')
      }
    ]
  },
  {
    path: '/daily_score',
    component: layout,
    children: [
      {
        path: '/daily_score',
        name: 'daily_score',
        component: () => import('../views/DailyScoreView.vue')
      }
    ]
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

// 防止连续点击多次路由报错
let routerPush = VueRouter.prototype.push;
let routerReplace = VueRouter.prototype.replace;
// push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push(location) {
  return routerReplace.call(this, location).catch(err => err)
}


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

let count = 0;

router.beforeEach((to, from, next) => {
  let user = localStorage.getItem('token');
  if (!user && (from.name !== 'login')) {
    if (to.name !== 'login' && !user) {
      if (!user && count !== 0) {
        Message.error('无token，请先登录')
      } else {
        Message.error('token失效，请重新登录')
      }
      next({name: 'login'})
    } else next()
  } else {
    next()
  }
})

export default router
