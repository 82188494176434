<template>
	<div class="header_class">
		<div class="header_logo">
			<div><img src="@/assets/img/logo02.png"></div>
			<el-divider direction="vertical"></el-divider>
			<div><span style="font-weight: bold;color: white;">软区</span></div>
			<div></div>
		</div>
		<div class="header_fun">
			<el-menu :default-active="this.$route.path" background-color="rgba(31,34,51)" text-color="#fff" active-text-color="#fff" mode="horizontal" router>
				<el-menu-item :index="item.path" v-for="(item,index) in funList">{{ item.name }}</el-menu-item>
			</el-menu>
		</div>
		<div class="header_people">
			<el-dropdown placement="bottom-start">
				<span class="el-dropdown-link">
					<el-avatar :src="userAvatar" style="width: 40px;height: 40px;"></el-avatar>
				</span>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item @click.native="getOut">退出登录</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
			<div><span style="color: white;font-size: small;margin-left: 5px;">{{user.name}}，{{userHello}}</span></div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import request from "@/utils/request";
export default {
	name: "Header",
	data(){
		return{
			funList:[
				{
					name:"首页",
					path:"/"
				},
				{
					name:"活动",
					path:"/activity"
				},
				{
					name:"班费",
					path:"/money"
				},
				{
					name:"出勤",
					path:"/attendance"
				},
				{
					name:"素拓",
					path:"/daily_score"
				}
			],
			// userAvatar:"https://img.pg-code-go.com",
			userAvatar:require('../../assets/img/logo01.png'),
			user:{},
			token:'',
			userHello:''
		}
	},
	created(){
		if(localStorage.getItem("token")){
			this.token = JSON.parse(localStorage.getItem("token"));
		}
		this.getUser()
	},
	mounted(){
		this.helloFun();
	},
	methods:{
		helloFun(){
			let time = new Date().getHours();
			if(time>=0&&time<=12){
				this.userHello = '上午好~'
			}else if(time>=12&&time<=18){
				this.userHello = '下午好~'
			}else if(time>=18&&time<=24){
				this.userHello = '晚上好~'
			}else if(time>=0&&time<=6){
				this.userHello = '凌晨好~'
			}else{
				this.userHello = '夜深了~'
			}
		},
		getOut(){
			this.$router.push('/login')
			localStorage.removeItem("token")
		},
		getUser(){
			request({
				url:'/dormitory/selectUserByToken',
				method:'get',
				params:{
					token:this.token
				}
			}).then(res => {
				if(res.code === '200'){
					this.$user = res.data;
					this.user = res.data;
					// console.log('user:'+JSON.stringify(this.$user))
				}
			})
		}
	}
}
</script>

<style scoped>
.header_class{
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.header_logo{
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.header_logo img{
	width: 40px;
	height: 40px;
}

.header_fun{
	height: 100%;
	display: flex;
	margin: 0 70px;
	justify-content: center;
	align-items: center;
}

.el-menu-item{
	width: 80px;
	text-align: center;
	border: 0 !important;
}

.el-menu-item.is-active{
	font-weight: bold;
	background-color: #757474 !important;
}

.header_people{
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
