<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  name: 'App',
	data(){
		return {
			token:'',
			user:{}
		}
	},
	created(){
		// console.log(this.$router)
		if(localStorage.getItem("token")){
			this.token = JSON.parse(localStorage.getItem("token"));
		}
	},
	mounted(){
		// console.log(this.$router)
		// this.getUser();
	},
	methods:{
		getUser(){
			request({
				url:'/dormitory/selectUserByToken',
				method:'get',
				params:{
					token:this.token
				}
			}).then(res => {
				if(res.code === '200'){
					this.$user = res.data;
					console.log('user:'+JSON.stringify(this.$user))
				}
			})
		}
	}
}
</script>

<style lang="less">
*{
	margin: 0;
	padding: 0;
}
</style>
